<template>
  <div class="wrapper">
    <img
      src="./assets/ppt_01.png"
      alt=""
    />
    <img
      src="./assets/ppt_02.png"
      alt=""
    />
    <img
      src="./assets/ppt_03.png"
      alt=""
    />
    <img
      src="./assets/ppt_04.png"
      alt=""
    />
    <img
      src="./assets/ppt_05.png"
      alt=""
    />
    <img
      src="./assets/ppt_06.png"
      alt=""
    />
    <img
      src="./assets/ppt_07.png"
      alt=""
    />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
  background: #000;
}
.wrapper {
  font-size: 0;
}
/* 媒体查询PC浏览器 */
@media (min-width: 1024px) {
  .wrapper {
    width: 1080px;
    margin: 0 auto;
  }
}
/* 媒体查询移动端 */
@media (max-width: 1024px) {
  .wrapper {
    width: 100%;
  }
}

img {
  display: block;
  width: 100%;
}
</style>
